import React, { useEffect } from "react";
import Background from "../../components/Background/Background.tsx";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import LogoAndTimer from "../../components/LogoAndTimer/LogoAndTimer.tsx";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";
import { useLogEventMutation } from "../../api/api.ts";
import createLogEventBody from "../../utils/createLogEventBody.js";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";

const BiometricsDenied = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const errorRedirectUrl = useSelector((state: RootState) => state.user.errorRedirectUrl);
    const sessionStorageErrorUrl = sessionStorage.getItem("errorRedirectUrl");
    const errorLink = errorRedirectUrl ? errorRedirectUrl : sessionStorageErrorUrl !== "undefined" ? sessionStorageErrorUrl : null;
    const biometricsDeniedComponent = getCompanyConfig("biometricsDeniedComponent");

    const [logEvent] = useLogEventMutation();

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.biometricsDenied.navToPage.status));
    }, []);

    if (errorLink) {
        window.location.reload();
        window.location.replace(errorLink);
    }

    const clickBack = () => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.biometricsDenied.clickBack.status));
        navigate(-1);
    };

    return (
        <>
            {!errorRedirectUrl && (
                <>
                    <Background>
                        {biometricsDeniedComponent ? (
                            biometricsDeniedComponent
                        ) : (
                            <>
                                <LogoAndTimer />
                                <Row className='h-100 mt-5 justify-content-between'>
                                    <Col xs={12}>
                                        <h1 className='mb-3 text-primary'>{t("biometricsDeniedPage.question")}</h1>
                                        <p className='mb-0'>{t("biometricsDeniedPage.description")}</p>
                                        <ul role='list'>
                                            <li role='listitem' aria-label={`${t("biometricsDeniedPage.truistAriaRec1")}`}>
                                                <span className='fw-bold'>{t("biometricsDeniedPage.continue")}: </span>
                                                {t("biometricsDeniedPage.toContinue1")}
                                                <b>{t("biometricsDeniedPage.toContinue2")}</b>
                                                {t("biometricsDeniedPage.toContinue3")}
                                            </li>
                                            <li role='listitem' aria-label={`${t("biometricsDeniedPage.truistAriaRec2")}`}>
                                                <span className='fw-bold'>{t("biometricsDeniedPage.exit")}: </span>
                                                {t("biometricsDeniedPage.toExit")}
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Col xs={12} className='mb-5'>
                                    <Button className='w-100' onClick={clickBack}>
                                        {t("biometricsDeniedPage.back")}
                                    </Button>
                                </Col>
                            </>
                        )}
                    </Background>
                </>
            )}
        </>
    );
};

export default BiometricsDenied;
