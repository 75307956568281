import React from "react";
import { Button, Col, Image, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { getNextPage } from "../../redux/slices/user.slice.ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CertifIDFooter from "../../assets/CertifID/CertifIDFooter.svg";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";

export type OwnProps = {
    continueBtnDisabled: boolean;
    retake: () => void;
    maxRetakes: number;
    retakeCount: number;
    handleContinueClicked: () => void;
    docType: "NA_ID" | "Passport" | "Passport Card";
    page: string;
};

const CaptureButtons: React.FC<OwnProps> = ({ handleContinueClicked, continueBtnDisabled, retake, maxRetakes, retakeCount, docType, page }) => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language } = user;
    const navigate = useNavigate();
    const url = `/${getNextPage(captureRequirements, page)}?token=${token}&version=${routerVersion}&language=${language}`;
    const secondButtonVariant = getCompanyConfig("secondButtonVariant");

    return (
        <>
            <Stack gap={3} className='d-flex align-items-center mt-4 mb-5'>
                <Button disabled={continueBtnDisabled} onClick={() => handleContinueClicked()} variant='primary' className='col-12 p-2'>
                    {t("captureButtons.continueButton")}
                </Button>
                <Button
                    onClick={() => retake()}
                    variant={maxRetakes === retakeCount ? "outline-secondary" : secondButtonVariant ? secondButtonVariant : "outline-primary"}
                    disabled={maxRetakes === retakeCount}
                    className='col-12 p-2 mb-5'
                >
                    {t("captureButtons.retakeButton")}
                </Button>
                {continueBtnDisabled ? (
                    <p>
                        {t("captureButtons.failedFindBarcode")}
                        <b>{t("captureButtons.retake")}</b>
                    </p>
                ) : null}
                {retakeCount === maxRetakes - 1 ? <p className='text-danger text-center'>{t("captureButtons.oneMoreRetake")}</p> : null}
                {retakeCount === maxRetakes ? <p className='text-danger text-center'>{t("captureButtons.noRetakes")}</p> : null}
            </Stack>
            {routerVersion === "certifid" ? (
                <Row className='mt-5 mb-5'>
                    <Col className='d-flex justify-content-center'>
                        <Image src={CertifIDFooter} aria-label='Powered by CertifID' />
                    </Col>
                </Row>
            ) : null}
        </>
    );
};

export default CaptureButtons;
