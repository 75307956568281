import { useEffect } from "react";
import Background from "../../components/Background/Background.tsx";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import { useLocation } from "react-router-dom";
import LogoAndTimer from "../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Container, Stack } from "react-bootstrap";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";
import { useLogEventMutation } from "../../api/api.ts";
import createLogEventBody from "../../utils/createLogEventBody.js";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";

const ErrorPage = () => {
    const [logEvent] = useLogEventMutation();
    const { t } = useTranslation();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");
    const sessionStorageErrorUrl = sessionStorage.getItem("errorRedirectUrl");

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.errors[404].navToPage.status, `redirectUrl: ${sessionStorageErrorUrl}`));
    }, []);

    if (sessionStorageErrorUrl) {
        window.location.reload();
        window.location.replace(sessionStorageErrorUrl);
    }

    const errorPage = getCompanyConfig("errorPage");

    if (errorPage) {
        return errorPage;
    }

    return (
        <>
            {!sessionStorageErrorUrl && (
                <Container>
                    <Background>
                        <LogoAndTimer />
                        <Stack gap={5} className='text-center mt-5'>
                            <h3>{t("errorPage")}</h3>
                            <p>{token}</p>
                        </Stack>
                    </Background>
                </Container>
            )}
        </>
    );
};

export default ErrorPage;
